@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;500;600;700;800;900&display=swap');

:root{
    --base-font: 'Raleway', sans-serif;
    --clr-white: #fff;
    --clr-black: #000;
    --clr-orange: #fd9644;
    --clr-dark: #1C1D1F;
    --clr-purple: #A435F0;
    --clr-pink: #E91E63;
    --clr-alice-blue: #F8F9FB;
    --spacing : 0.1rem;
    --transition: all 300ms linear;
}

/* global styles and resets */
/* *,
*::after,
*::before{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}
html{
    font-size: 10px;
}
body{
    font-size: 1.6rem;
    line-height: 1.6;
    font-family: var(--base-font);
    color: var(--clr-black);
}
ul{
    list-style-type: none;
}
a{
    text-decoration: none;
    color: unset;
}
a:hover{
    color: unset;
}
h1, h2, h3, h4, h5, h6{
    letter-spacing: var(--spacing);
    text-transform: capitalize;
}
img{
    width: 100%;
    display: block;
}
.img-cover{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
button{
    cursor: pointer;
    outline: 0;
    border: none;
    background-color: transparent;
    font-family: inherit;
    font-size: 1.8rem;
}
input, textarea, select{
    outline: 0;
    border: none;
    resize: none;
} */

/* container */
/* .container{
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.8rem;
}

@media screen and (min-width: 768px){
    .container{
        padding: 0 3.4rem;
    }
}

@media screen and (min-width: 1400px){
    .container{
        padding: 0 1rem;
    }
} */

/* flex and grid */
/* .flex{
    display: flex;
    align-items: center;
}
.flex-wrap{
    flex-wrap: wrap;
}
.flex-column{
    flex-direction: column;
}
.flex-center{
    justify-content: center;
}
.flex-between{
    justify-content: space-between;
}
.flex-start{
    justify-content: flex-start;
}
.flex-end{
    justify-content: flex-end;
}
.grid{
    display: grid;
} */

/* display */
/* .d-block{
    display: block;
}
.d-inline-block{
    display: inline-block;
}
.d-inline{
    display: inline;
} */

/* height and width */
/* .h-100{height: 100%;}
.w-100{width: 100%;} */

/* backgrounds */
/* .bg-white{background-color: var(--clr-white);}
.bg-black{background-color: var(--clr-black);}
.bg-transparent{background-color: transparent;}
.bg-orange{background-color: var(--clr-orange);}
.bg-dark{background-color: var(--clr-dark);}
.bg-purple{background-color: var(--clr-purple);}
.bg-alice-blue{background-color: var(--clr-alice-blue);} */

/* text and paras */
/* .text{
    line-height: 1.6;
    font-weight: 400;
    font-size: 18px;
} */

/* text alignment */
/* .text-center{text-align: center;}
.text-start{text-align: left;}
.text-end{text-align: right;} */

/* text transform */
/* .text-uppercase{text-transform: uppercase;}
.text-capitalize{text-transform: capitalize;}
.text-justify{text-align: justify;} */

/* text colors */
/* .text-white{color: var(--clr-white);}
.text-black{color: var(--clr-black);}
.text-orange{color: var(--clr-orange);}
.text-dark{color: var(--clr-dark);}
.text-purple{color: var(--clr-purple);}
.text-pink{color: var(--clr-pink);}
.text-alice-blue{color: var(--clr-alice-blue);} */

/* font weights */
.fw-2{font-weight: 200;}
.fw-3{font-weight: 300;}
.fw-4{font-weight: 400;}
.fw-5{font-weight: 500;}
.fw-6{font-weight: 600;}
.fw-7{font-weight: 700;}
.fw-8{font-weight: 800;}

/* font sizes */
.fs-12{font-size: 12px;}
.fs-13{font-size: 13px;}
.fs-14{font-size: 14px;}
.fs-15{font-size: 15px;}
.fs-16{font-size: 16px;}
.fs-17{font-size: 17px;}
.fs-18{font-size: 18px;}
.fs-19{font-size: 19px;}
.fs-20{font-size: 20px;}
.fs-21{font-size: 21px;}
.fs-22{font-size: 22px;}
.fs-23{font-size: 23px;}
.fs-24{font-size: 24px;}
.fs-25{font-size: 25px;}
.fs-26{font-size: 26px;}

/* letter spacing */
/* .ls-1{letter-spacing: 1px;}
.ls-2{letter-spacing: 2px;} */

/* margin & padding */
/* .mx-auto{
    margin-right: auto;
    margin-left: auto;
} */

/* opacity */
/* .opacity-09{opacity: 0.9;}
.opacity-08{opacity: 0.8;}
.opacity-07{opacity: 0.7;}
.opacity-06{opacity: 0.6;}
.opacity-05{opacity: 0.5;} */

/* animation and transition stopper */
/* .resize-animation-stopper *{
    animation: none!important;
    transition: none!important;
} */
